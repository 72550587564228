import * as css from './transaction.css';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {XHR_LOADING_STATE} from '../../../../../assets/constants/constants';
import {formatRealtimeNumber, formatRealtimeDecimal} from '../../../functions';
import TransactionsTemplate from './transactionsTemplate';
import approximateFraction from '../../../../../functions/approximateFraction';
import {CircleProgress, Insight, MainDetail} from '../../windowItems/windowItems';

const Transactions = () => {
    const {t} = useTranslation('realtime');
    const {all: stats, today: {allVisitors: visitsToday} = {allVisitors: null}, mostFrequentTicketsAmount, now: {allVisitors: visitsNow} = {allVisitors: null}} = useSelector((state: any)=>state.realtime.event.eventStats) || {};
    const currencySymbol = useSelector((state: any)=>state.event.currencySymbol);
    const isLoading = useSelector((s:any)=>s.realtime?.event?.getEventStatsXHR === XHR_LOADING_STATE);
    const [numerator, denominator] = approximateFraction(stats?.amountOrders || 0, stats?.uniqueUsers || 1);
    
    if(isLoading) {
        return <TransactionsTemplate />;
    }
    if(!stats)
        return <></>;
    return (
        <css.container>
            <css.content>
                <MainDetail item={{title: 'visitors', value: stats.uniqueUsers}} />
                <MainDetail item={{title: 'pageViews',value: stats.allVisitors}} />
                <MainDetail item={{title: 'transactionsTitle',  value: stats.amountOrders}} />
            </css.content>
            <css.details>
                <css.stats isListFull={stats?.uniqueUsers || mostFrequentTicketsAmount} >
                    {stats?.uniqueUsers && <Insight item={{
                        text: t('visitorsFraction', {numerator, denominator}),
                        value: t('know')
                    }}/>}
                    <Insight item={{
                        text: 'visitedToday',
                        value: (visitsToday||0) + (visitsNow||0)
                    }}/>  
                    <Insight item={{
                        text: 'visitDuration',
                        value: `${formatRealtimeDecimal(stats.averageVisitDuration/60, true) || 0} ${t('minutesShort')}`
                    }}/>  
                    {mostFrequentTicketsAmount && <Insight item={{
                        text: t('mostFrequentTicketsAmount', {amount: mostFrequentTicketsAmount}),
                        value: t('know')
                    }}/> }
                    <Insight item={{
                        text: 'averageTickets',
                        value: ((stats.ticketsSold || 0) / (stats.amountOrders || 1)).toFixed(1)
                    }}/>  
                    <Insight item={{
                        text: 'averagePrice',
                        value: `${formatRealtimeNumber((stats.totalPrice || 0) / (stats.amountOrders || 1)||0, true)} ${currencySymbol} `
                    }}/> 
                </css.stats>
                <CircleProgress amount={stats.uniqueUsers} sold={stats.amountOrders} text={'conversion'} />
            </css.details>
        </css.container>
    );
};

export default Transactions;