import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import TopMenu from './topMenu/TopMenu';
import * as css from './Layout.css';
import EventMenu from './eventMenu/EventMenu';
import MyIframe from './myIframe/MyIframe';
import {Switch, useParams} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {menuEvent} from './menu';
import {menuAccount} from './menuAccount';
import Navigation from '../layout/navigation/Navigation';
import {EXCLUDE_PACKAGE_SUPPORT, REACT_APP_ENV, TICK_DOMAIN_MY} from '../config/config';
import SupportComponent from './supportComponent/SupportComponent';
import LayoutButtons from './layoutButtons/LayoutButtons';
import {dispatchEvent} from '../hooks/dispatchEvent';
import AppComponent from '../pages/components/appComponent/AppComponent';
import BoxofficeIframe from './boxofficeIframe/BoxofficeIframe';
import {logout} from '../redux/user/userActions';
import {hasAccountRoleAccess, hasEventRoleAccess, isMaster} from '../functions/permission';
import Page404 from '../pages/page404/Page404';
import useTablet from '../hooks/useTablet';
import setGlobalState from '../redux/setGlobalState';
import store from '../store';
import {updateDesignPop} from '../redux/event/eventActions';
import {newBrandingOldEvent} from '../functions/customSystemPixel';

export const openDesignPop=()=>{
    const event = store.getState().event;
    const eid = REACT_APP_ENV==='production'?47110:35849;
    const isShowDesignPop=(event.eid>eid)||REACT_APP_ENV==='local';
    if(!event.showPop && event.previewLastUpdate && event.type==='ticket'&&isShowDesignPop){
        setGlobalState('ui.show.isOpenDesignPop',true);
        if(!event.showPop)
            updateDesignPop().then();
    }   
};

let iframeRef;
export const iframeClick = (buttonClick, type) => {
    const iFrame = iframeRef?.current?.contentWindow;
    if(iFrame){
        iFrame.postMessage({buttonClick: buttonClick, type: type}, TICK_DOMAIN_MY);
    }
    if(buttonClick==='logout'){
        logout().then(()=>{
            dispatchEvent('401Exception');
        }).catch(()=>{
            dispatchEvent('401Exception');
        });
    }
};

function Layout() {
    const account = useSelector(state => state.account);
    const event = useSelector(state => state.event);
    const {eid: eidParam,pid: pidParam}=useParams();
    const [miniMenu, setMiniMenu] = useState(0);
    const [form, setForm] = useState(0);
    const isTablet = useTablet();
    const {page} = useParams();
    const duplicateSourceEid = useSelector(s=>s.ui.event.duplicateSourceEid);
    const oldSystem = useSelector(s => s.realtime.user.oldSystem);

    iframeRef = useRef();

    useEffect(() => {
        if(page==='design'&& event.eid&& !event.updateDesignPopXHR)
            openDesignPop();       
    }, [page,event.eid]);

    useEffect(() => {
        if(page!=='realtime')
            setGlobalState('realtime.user.oldSystem', false);
    }, [page]);

    const [positionValue, setPositionValue] = useState('');

    const setPosition = (value) => {
        setPositionValue(value);
    };
    const showInIframe=(path)=>{
        switch (path) {
            // case 'tickets':
            //     return event?.type=== 'givechak' || event?.type=== 'timetable' || event?.hasScheduledOrPriceFloatTicket;        
            case '/event':
                return   !['ticket','area','hub'].includes(event.type)&& !duplicateSourceEid;
            case '/tickets/branding/:pageId':
            case '/courses/branding/:pageId':
                return   (REACT_APP_ENV==='production'&&event.eid<=58410&&!newBrandingOldEvent(event.eid));
            case '/realtime':
                return oldSystem || (event.type === 'givechak' && !isMaster());
            default:
                break;
        }
    };
    const showTicketsInIframe=()=>{
        return event?.type=== 'givechak' || event?.type === 'timetable' || event?.hasScheduledOrPriceFloatTicket || event?.hasScheduledTicket ;
    };
    const arrayPath = [];
    Object.entries(menuEvent).map(([, item]) => {
        if(item.events&& !item.events.includes(event.type))
            return;
        if((item.component === 'MyIframe'  &&((item.access==='master'&&isMaster())|| hasEventRoleAccess(item.access)) ) 
        ||  showInIframe(item.path)
        || (item.title === 'tickets' && (event?.ready < 5|| showTicketsInIframe())))
            arrayPath.push('/e/' + event?.eid + item.path);
    });
    Object.entries(menuAccount).map(([, item]) => {
        if(item.component === 'MyIframe' && hasAccountRoleAccess(item.access))
            arrayPath.push('/p/' + account.pid + item.path);
    });
    const routesEvent = Object.entries(menuEvent).filter(([, item]) => (
        item.component 
        && !(item.title === 'tickets' && showTicketsInIframe()) 
        && !showInIframe(item.path)
        && item.component !== 'MyIframe'
        && hasEventRoleAccess(item.access)
        && (item.events && item.events.includes(event.type))
    ));

    const routesPackages = Object.entries(menuAccount).filter(([, item]) => (item.component && item.component !== 'MyIframe' && hasAccountRoleAccess(item.access)));
    const userMessageShow = useSelector((state) => state.ui.userMessage.text);
    const isRealtime = page === 'realtime' && !oldSystem && (['ticket', 'area'].includes(event.type) || (['givechak'].includes(event.type) && isMaster()));

    return (
        <>  
            <BoxofficeIframe/>
            <css.layout id='layout' marginValue={positionValue} className={userMessageShow && ' show'}>
                {
                    // <Router basename={(eid ? "/e/" + eid : "/p/" + pid)}>
                    <>
                       
                        <TopMenu updateLayout={setPosition}/>
                        { <css.layoutContent>
                            {(!isTablet && event.eid && event.ready === 5) &&
                        <EventMenu updateMiniMenu={(mini) => setMiniMenu(mini)}/>}
                            <css.layoutContentFrame
                                className={((event && event.ready < 5) && 'build ') + (form && ' form ') + (event && ' event')}
                                mini={miniMenu}
                                realtime={isRealtime}>
                                {(eidParam && (!isRealtime)) && <Navigation/>}
                                <css.MyComponent className={`${userMessageShow && 'stop-click'} ${pidParam && 'account'}`} >
                                    <Switch>
                                        <Route exact path={arrayPath} render={() => <MyIframe iframeRef={iframeRef}/>}/>
                                        {event?.eid&&routesEvent.map(([key, item]) => {
                                            return <Route key={key} exact path={'/e/' + event?.eid + item.path}
                                                component={item.component}/>;
                                        })}
                                        {account?.pid&&routesPackages.map(([key, item]) => {
                                            return <Route key={key} path={'/p/' + account.pid + item.path}
                                                component={item.component}/>;
                                        })}
                                        <Route path={'*'}  render={() => <Page404 eidParam={eidParam} pidParam={pidParam}/>}/>
                                    </Switch>
                                </css.MyComponent>
                            </css.layoutContentFrame>
                        </css.layoutContent> }

                    </>
                }
                {!EXCLUDE_PACKAGE_SUPPORT.includes(account?.pid) && <SupportComponent/>}
                {(isTablet && (event) && event.ready===5 && iframeRef.current) && <LayoutButtons/>}
            </css.layout> 
          
            <AppComponent setForm={setForm}/>
        </>
    );
}

export default Layout;