import {bag, copy, location, nisBell, totalFutureIcon, rect, area} from '../../../../assets/symbols/symbols/Symbols.css';
import * as css from './EventSnapshotItem.css';
import ProgressBar from '@ramonak/react-progress-bar';
import {TICK_DOMAIN} from '../../../../config/config';
import {Link} from 'react-router-dom';
import {changeAccountMessage} from '../../../userPackages/UserPackages';
import {useTranslation} from 'react-i18next';
import {formatDecimal, formatNumber} from '../../../../library/numberFunction';
import TooltipAbsolute from '../../../../component/tooltip/tooltipAbsolute/TooltipAbsolute';
import getCountryByTimeZone from '../../../../library/getCountryByTimeZone';
import useShow from '../../../../hooks/useShow';

function EventSnapshotItem({event,getDate,openSnapshotPop}) {
    
    const {t} = useTranslation('layout');
    const sold= event.tickets?.sold;
    const amount =event.tickets?.amount;
    let percent = (!sold||!amount)?0:(sold * 100) / amount; 
    const accountT=useTranslation('package');
    const areaShow = useShow();

    const areaClick = () =>{
        if(areaShow.isOpen){
            areaShow.close();
        } else areaShow.open();
    };
   
    return (

        <css.item area={areaShow.isOpen}>
            <css.wrapAll>
                <css.rightPart>
                    <Link to={`/e/${event.eid}/realtime`} onClick={()=>{changeAccountMessage(event,accountT,history),openSnapshotPop.close();}}>  <css.image image={event.fbImg}/></Link>
                    <css.rightContext>
                        <css.right>
                            <Link to={`/e/${event.eid}/realtime`} onClick={()=>{changeAccountMessage(event,accountT,history),openSnapshotPop.close();}}>  <css.title>{event.title}</css.title></Link>
                            <css.wrapTexts>
                                <css.date> {`${getDate(event.type,event.timeStart)} ${getCountryByTimeZone(event.timeZone)}`} </css.date>
                                {event.location&&<css.location>
                                    <css.icon icon={location} />
                                    <css.wrapLocation>{event.location}</css.wrapLocation>
                                </css.location>}
                            </css.wrapTexts>
                        </css.right>
                        <css.middle>
                
                            <css.percent>{Math.round(percent)}%</css.percent>
                            <ProgressBar animateOnRender={1} dir={'rtl'} width={'50px'} height={'8px'} bgColor={'#76D6E8'}  completed={percent}  customLabel={'   '} />
                        </css.middle>
                    </css.rightContext>
                    <css.leftContext>
                        <css.wrapItem>
                            <css.icon icon={bag}/>
                            <css.widthText>{formatNumber(event.transactions, true)}</css.widthText>
                        </css.wrapItem>
                        <css.wrapItem>
                            <css.icon icon={rect}/>
                            <css.widthText>{`${formatNumber(sold?sold:0, true)}/${formatNumber(amount?amount:0, true)}`}</css.widthText>
                            {event.type==='area'&&<TooltipAbsolute text={t('availableSeatsMessage')} bottom={true}/>}
                        </css.wrapItem>
                        <css.wrapItem>
                            <css.wrapPrice>
                                <css.wrap>
                                    <css.icon icon={nisBell}/>
                                    <css.widthText> {formatDecimal(event.totalPrice, true)}&nbsp;{!!event.currencySymbol&&event.currencySymbol}</css.widthText>
                                </css.wrap>
                                {event?.totalFuture >0 && <css.wrap>
                                    <css.icon icon={totalFutureIcon}/>
                                    <css.widthText> {formatDecimal(event.totalFuture, true)}&nbsp;{!!event.currencySymbol&&event.currencySymbol}</css.widthText>
                                </css.wrap>}
                            </css.wrapPrice>
                            {event.type==='area'&& <css.areaArrow isOpen={areaShow.isOpen} onClick={areaClick}/>}
                        </css.wrapItem>
                    </css.leftContext>
                </css.rightPart>
                <css.leftPart icon={copy} onClick={(e)=>{ e.stopPropagation();window.open(TICK_DOMAIN + '/' + event.name);}}/>
            </css.wrapAll>
            {areaShow.isOpen && <css.wrapArea>
                <css.areaItemRight>
                    <css.icon icon={area}/>
                    <css.widthText>{t('area')}</css.widthText>
                </css.areaItemRight>
                <css.areaItemLeft>
                    <css.text>{t('freeSeats')}</css.text><css.widthText>{event.tickets?.free?.sold || 0}/{event.tickets?.free?.amount || 0}</css.widthText>
                    <css.text>{t('left')} ({(event.tickets?.free?.amount || 0) - (event.tickets?.free?.sold || 0)})</css.text>
                    <span>&#8901;</span>
                    <css.text>{t('reservedSeats')}</css.text><css.widthText>{event.tickets?.reserved?.sold || 0}/{event.tickets?.reserved?.amount || 0}</css.widthText><span>&#8901;</span>  
                    <css.text>{t('closedSeats')}</css.text><css.widthText>{event.tickets?.closed || 0}</css.widthText>  
                </css.areaItemLeft>
            </css.wrapArea>}
        </css.item>

    );
}
export default EventSnapshotItem;