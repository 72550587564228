import request from '../../library/request';
import setGlobalState, {unsetGlobalState} from '../setGlobalState';
import store from '../../store';

//type = events or searchEvents
export const getEvents = async (fields, type = 'events') => {
    const res = await request('suggestions.getEvents', 'get', '/events/', fields);
    if(res===false) return;
    setGlobalState('suggestions.isEventsFound', !!res?.events?.length);

    if(fields.offset)
        setGlobalState(`suggestions.${type}`, [...store.getState().suggestions[type], ...res?.events]);
    else
        setGlobalState(`suggestions.${type}`, res?.events);

    /*const newEvents=produce(store.getState().suggestions.events,draftEvents=>{
        res.events.forEach((event)=>{
            draftEvents.push(event);
        });
        // you cannot use draftEvents=assignNewValue;
    });

    setGlobalState("suggestions.events",newEvents);
*/

    //setGlobalState("stats.events",[1,2,3]);

    //setGlobalState("stats",null,"reset");
};

//getPackages
export const getAccounts = async (fields) => {
    const res = await request('suggestions.getAccounts', 'get', '/packages/', fields);
    if(res?.packages)
        if(store.getState().suggestions.accounts.length)
            setGlobalState('suggestions.accounts', [...store.getState().suggestions.accounts, ...res.packages]);
        else
            setGlobalState('suggestions.accounts', res.packages);
};

export const getEventsReport = async (getAll=false,fields) => {
    const res = await request('suggestions.getEventsReport', 'get', '/events/', fields);
    if(fields.offset)
        setGlobalState('suggestions.eventsReport', [...store.getState().suggestions.eventsReport, ...res.events]);
    else
        setGlobalState('suggestions.eventsReport', res.events);
    if((res.events.length<fields.limit)||store.getState().suggestions.eventsReport.length>200){
        setGlobalState('ui.exportExcel.isNotLoadAll',false);
        return; 
    }
    if(getAll)
        getEventsReport(getAll,{...fields,offset:store.getState().suggestions.eventsReport.length});
};

//getUserEvents
export const getEventsByRole = async (fields) => {
    const res = await request('suggestions.getEventsByRole', 'get', '/events/', fields);
    setGlobalState('suggestions.eventsByRole', [...store.getState().suggestions.eventsByRole, ...res.events]);
};
// export const getEventsTypes = async (fields) => {
//     const res = await request('suggestions.getEventsTypes', 'get', '/eventsTypes', fields);
//     setGlobalState('suggestions.eventsTypes', res.eventsTypes);
//     console.log('suggestions.eventsTypes');
//     console.log( res.eventsTypes);
// };
// //getPackages
// //I didn't find how used it
// export const getAccount=async(fields)=>{
//     const res=await request('suggestions.getAccount',"get", '/packages/',fields)
//     setGlobalState('suggestions.accounts',res?.permissions||[])
// }

export const deleteEvent = async (eid) => {
    await request('suggestions.deleteEvent', 'delete', `/event/${eid}`);
    const index = store.getState().suggestions.events.findIndex(e => e.eid === eid);
    if(index>-1)
        unsetGlobalState(`suggestions.events[${index}]`);
};
export const updateEvent = async (eid, fields, type) => {
    await request(`event.${type}`, 'put', `/event/${eid}`, fields);
    const index = store.getState().suggestions.events.findIndex(e => e.eid === eid);

    setGlobalState(`suggestions.events[${index}]`, {...store.getState().suggestions.events[index], ...fields});
    if(eid===store.getState().event.eid && fields.hasOwnProperty('active'))
        setGlobalState('event.settings.event.active', fields.active);
};
export const deleteCourse = async (eid) => {
    await request('suggestions.deleteEvent', 'delete', `/event/${eid}`);
};