import styled from 'styled-components';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

export const EventMenuFormMain=styled.div`
    display:flex;
    justify-content: center;
    position: absolute;
    top: -15px;
    z-index:${zIndex.baseHigh};
    width: max-content;
    margin-inline-start:calc(50% - 94px);
`;
export const EventMenuForm=styled.div`
    background-color: #FFEB00;
    width: max-content ;
    margin: 0 5px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 22px;
    padding:${props=>props.theme.mini?'5px 10px':' 5px 20px'};
    font-size: ${p=>p.theme.mini? (p.theme.rtl ? 12 : 10):14}px;
    font-weight: 600;
    cursor: pointer;
    display:flex;
    ${props => props.theme.mini&&'line-height: 10px;'};  
    text-align:center;
    position:relative;
    ${p => p.theme.mini ? (p.theme.rtl ? 'right:57px' : 'left:57px'): ''}
    
    `;
export const  EventMenuFormText= styled.div`

`;
export const  EventMenuFormMore= styled.div`
`;
export const  EventMenuOP= styled.div`
    display: flex;
    width: max-content;
    inset-inline-start: 100px;
    input{
        width: 5px;
        height: 0;
        border: 0;
        position: absolute;
    }
    &.miniDisplay{
        display: none;
    }
`;
const opImages = {
    share: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.266' height='17.896' viewBox='0 0 24.266 17.896'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%232c2c2c;%7D.b%7Bfill:none;stroke:%232c2c2c;stroke-linecap:round;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-4.057 -7.458)'%3E%3Cpath class='a' d='M6.531,10.3l-.181.336a1.126,1.126,0,0,0,.4,1.525l2.007,1.153.019.009a1.116,1.116,0,0,0,1.512-.427l.794-1.361,1.19.322,1.716,1.421a.642.642,0,0,0,.9-.091.435.435,0,0,0,.072-.108l1.59-2.761a.621.621,0,0,0-.236-.863c-.027-.018-.059-.037-.091-.055l-2.1-.776L5.342.179a.633.633,0,0,0-.9.018.645.645,0,0,0-.108.149L.084,7.711a.64.64,0,0,0,.236.872.42.42,0,0,0,.15.059h0L6.531,10.3Zm.763.208h0l3.024.817-.694,1.2a.368.368,0,0,1-.468.137l-.018-.009-2-1.153h0a.361.361,0,0,1-.123-.5l.277-.49ZM13.16,9.464h0l-.653,1.126L1.6,7.629,5.047,1.658,13.16,9.464Zm.028,1.493h0l.626-1.089,1.258.463L14.218,11.8l-1.03-.846Z' transform='translate(11.228 11.904) rotate(-2)'/%3E%3Cline class='b' x2='2.696' y2='1.281' transform='matrix(0.883, 0.469, -0.469, 0.883, 9.82, 8.81)'/%3E%3Cline class='b' y1='1.281' x2='2.696' transform='matrix(0.883, 0.469, -0.469, 0.883, 6.01, 15.974)'/%3E%3Cline class='b' x1='3.417' transform='matrix(0.883, 0.469, -0.469, 0.883, 5.788, 11.987)'/%3E%3C/g%3E%3C/svg%3E",
    copy:"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.745' height='14.75' viewBox='0 0 14.745 14.75'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%232c2c2c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M210.549,764.506a.569.569,0,0,1,.26-.758.562.562,0,0,1,.758.243,2.318,2.318,0,0,1,.214,1.376,2.164,2.164,0,0,1-.634,1.231l-.025.033-2.414,2.422H208.7l-.033.029a2.238,2.238,0,0,1-3.143-.029h0l-1.553-1.544V767.5l-.033-.033a2.248,2.248,0,0,1,.033-3.147h0l.033-.037,2.414-2.414h0a2.274,2.274,0,0,1,1.236-.63,2.222,2.222,0,0,1,1.367.214.565.565,0,1,1-.507,1.009,1.127,1.127,0,0,0-.684-.111,1.2,1.2,0,0,0-.614.321l-2.422,2.422-.021.021-.008,0v0a1.151,1.151,0,0,0-.321.795,1.109,1.109,0,0,0,.3.766l.016.021h.008l1.545,1.553v.008a1.153,1.153,0,0,0,.791.317,1.094,1.094,0,0,0,.766-.3l.025-.012v-.008h0l2.422-2.422.025-.025a1.142,1.142,0,0,0,.313-.614,1.075,1.075,0,0,0-.115-.684Zm-1.882.646h0a.566.566,0,0,1-.8-.8l4.831-4.827a.564.564,0,0,1,.795,0,.55.55,0,0,1,0,.795l-4.827,4.835Zm0,3.929h0a.561.561,0,0,1-.766-.029l.766.029Zm-4.728-1.615h0a.558.558,0,0,1,.033-.77l-.033.77Zm6.878-7.294h0a.565.565,0,0,1-.251.754.576.576,0,0,1-.766-.247,2.244,2.244,0,0,1-.206-1.376,2.273,2.273,0,0,1,.622-1.236l.029-.021,2.422-2.422h0l.029-.041a2.27,2.27,0,0,1,1.561-.622,2.223,2.223,0,0,1,1.59.663h.008l1.545,1.545v.008l.029.025a2.253,2.253,0,0,1-.029,3.155h0l-.033.024-2.414,2.414h0v0a2.25,2.25,0,0,1-2.611.412.562.562,0,1,1,.511-1,1.18,1.18,0,0,0,.692.1,1.156,1.156,0,0,0,.605-.309h0l2.418-2.422.025-.033h.008a1.136,1.136,0,0,0,.025-1.557l-.025-.025h-.008l-1.545-1.544,0,0h0a1.16,1.16,0,0,0-.791-.321,1.131,1.131,0,0,0-.77.309l-.021.012,0,0h0l-2.41,2.414-.033.029a1.144,1.144,0,0,0-.313.614,1.132,1.132,0,0,0,.111.692Zm1.878-4.589h0a.562.562,0,0,1,.77.041l-.77-.041Zm4.733,1.619h0a.561.561,0,0,1-.029.766l.029-.766Z' transform='translate(-203.312 -754.963)'/%3E%3C/svg%3E",
    publish:'',
    QrCode: symbol.QrCode

};
export const smallBtn=styled.div`
    width: 30px;
    height: 30px;
    background-color: #FFEB00;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    background-image: url("${props=>opImages[props.img]}");
    transition:all 100ms;
    cursor: pointer;
`;
export const  EventMenuOPChild= styled(smallBtn)`
    font-size:0;
    margin: 0 4px;
    &.copied{
        position: absolute;
        font-size: 12px;
        //width: -webkit-max-content;
        //width: -moz-max-content;
        width: max-content;
        padding: 0 20px;
        border-radius: 30px;
        line-height: 30px;
        background: #eaeaea;
        font-weight: 600;
    }
`;

export const EventMenuShareOPChild = styled(EventMenuOPChild)`
    background-size: 20px;
`;
export const QRCodeButton = styled(EventMenuOPChild)`
    background-size: 18px;
`;

export const  EventMenuFormPublish= styled(smallBtn)`
    background-color: #FFEB00;
    box-shadow: 0 3px 6px #00000029;
    position:relative;
    background-image: url("${symbol.symbolEventDark['publish']}");
    background-repeat: no-repeat;
    background-size: 17px 17px;
    background-position: center center;
    display:${props=>props.theme.mini?'none':'block'};
`;
