import createReducer from '../createReducer';
import {TRealtime} from './realtime.type';

export const initialStateRealtime:TRealtime = {
    user:{
        countEvents:0,
        eventsTypes:[],
        realtimeDesign: null,
        getStatsXHR:null,
        oldSystem: false
    },
    event:{
        eventStats: {
            today: [],
            yesterday: [],
            week: [],
            month: [],
            all: [],
            other: [],
            now:{}
        },
        realtimeText: {},
        cash: {},
        tickets: {},
        seats: {},
        moreInfo: {},
        soldTickets: null,
        getEventStatsXHR: null,
        getRealtimeXHR: null,
        soldTicketsXHR: null,
        weatherXHR: null,
        getRealtimeTextXHR:null

    },
    systemMessage: {popMessages: [], windowMessages: [],isViewed:false}
};

const reducer = createReducer('realtime',initialStateRealtime);

export default reducer;